import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2em",
        minWidth: 275,
        backgroundColor: "aliceblue",
    },
    summary: {
    },
    content: {
        display: "block"
    },
    paneldetails: {
        paddingTop: 0,
        paddingBottom: 0
    },
    cardcontent: {
        padding: "16px 16px 0 16px"
    },
    chips: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chip: {
        backgroundColor: "#cce7ff"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    h1: {
        fontSize: "1.2em",
    },
    h4: {
        marginTop: "0em",
        marginBottom: "0.2em"
    },
    ul: {
        marginTop: 0
    },
    pos: {
        marginBottom: 12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        display: "flex",
        justifyContent: "space-between"
      },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
}));

export default function Lectures() {

    const lectures = [
        {
            title: "Programmation orientée objet en Java",
            description: "Cours de POO orienté sur la conception d'API et la programmation déclarative",
            years: [2018, 2019, 2020, 2021],
            school: "Hepia",
            topics: [
                "polymorphisme et mécanisme d'abstraction",
                "programmation orientée objet",
                "programmation déclarative",
                "programmation générique et variance",
                "immutabilité",
                "bonnes pratiques"],
            links: [
              {
                "title": "support de cours",
                "link": "https://cavat.website/poo/"
              },
                {
                    "title": "git 2021",
                    "link": "https://githepia.hesge.ch/joel.cavat/poo2021/"
                }]
        },
        {
            title: "Base de données",
            description: "Base de données relationnelles, conception, réalisation et manipulation",
            years: [2021],
            school: "Hepia",
            topics: [
                "étude du modèle conceptuel (modèle entité-association)",
                "étude du modèle logique (modèle relationnel)",
                "approfondissement de concepts avancés de modélisation (spécialisation, généralisation, associations ternaires...)",
                "mettre en place une stratégie de cohérence des données en appliquant des contraintes d'intégrités référentielles",
                "langage SQL pour la gestion et la manipulation",
                "fonctionnalités avancées du langage tel que : les transactions, les déclencheurs, les procédures stockées..."],
            links: []
        },
        {
            title: "Algèbre relationnelle",
            description: "Algèbre relationnelle: formalisme des bases de données et conception basée sur des méthodes formelles",
            years: [2021],
            school: "Hepia",
            topics: [
                "formalisation théorique des bases de données relationnelles",
                "étude formelle du modèle relationnel",
                "algèbre relationnel",
                "normalisation"],
            links: []
        },
        {
            title: "Système de base de données",
            description: "Base de données relationnelles, conception, réalisation et manipulation",
            years: [2018, 2019, 2020],
            school: "Hepia",
            topics: [
                "conception et réalisation de base de données relationnelles",
                "formalisation et algèbre relationnelle",
                "modélisation basée sur les dépendances fonctionnelles et les dépendances d'inclusion",
                "langage SQL",
                "introduction aux bases de données dénormalisées"],
            links: [
                {
                    "title": "supports de cours",
                    "link": "https://githepia.hesge.ch/joel.cavat/poo2019/"
                }]
        },
        {
            title: "Application Web",
            description: "Initiation aux technologies web (html, css et js)",
            years: [2019],
            topics: [
                "html 5",
                "sélecteurs et motifs css, différents motifs (et, ou, à l'intérieur de, immédiatement après, ...",
                "pseudo-classes et pseudo-éléments css",
                "javascript (notion de base)"
            ],
            school: "Hepia",
            links: [
                {
                    "title": "supports en ligne",
                    "link": "https://cavat.website/web2018/chapitre1.html"
                }, {
                    "title": "exemple",
                    "link": "https://cavat.website/projetweb2018/"
                }
            ]
        }, {
            title: "Informatique I",
            description: "Algorithmes et programmation en Python 3",
            years: [2015, 2016, 2017, 2018, 2019],
            school: "HEIG-VD",
            topics: [
                "structures de contrôle et principales structures de données",
                "fonctions et composition de fonctions",
                "manipulation de collections",
                "lecture/écriture de fichiers"
            ],
            links: []
        }, {
            title: "Informatique II",
            description: "Base de données et introduction aux technologies WEB",
            years: [2016, 2017, 2018, 2019, 2020],
            topics: [
                "introductions aux principales technologies web (html, css, php)",
                "conception et réalisation de bases de données relationnelles",
                "manipulation à l'aide du langage SQL (DML)"
            ],
            school: "HEIG-VD",
            links: []
        }
    ];

    const continuousTeaching = [{
            title: "Python 3",
            description: "Environ 5-6 jours de formation. Présentation et approfondissement du langage et ateliers pratiques.",
            years: [2018, 2019, 2022],
            school: "Hepia",
            topics: [
                "structures de contrôle et principales structures de données",
                "fonctions et composition de fonctions",
                "manipulation de collections",
                "web scraping",
                "data processing",
                "poo et prog. fonctionnelle en Python"
            ],
            links: [{
                "title": "supports de cours",
                "link": "https://githepia.hesge.ch/cours/python3-public/"
            }]
        }];

    const secondaryLectures = [
        {
            title: "Programmation fonctionnelle en Scala // Dr. Jean-Luc Falcone",
            description: "Cours de programmation fonctionnelle à l'aide du langage Scala",
            years: [2016, 2017, 2018, 2019, 2020, 2021],
            school: "Hepia",
            links: [
                {
                    "title": "supports de cours de J-L Falcone",
                    "link": "https://gitlab.unige.ch/courses/scalapub"
                }]
        },
        {
            title: "Langage JavaScript - Université d'été // Dr. Jean-Luc Falcone",
            description: "Approfondissement d'un langage orienté objet sans classes, fortement inspiré des langages fonctionnels",
            years: [2017, 2018, 2019],
            school: "Hepia",
            links: [
                {
                    "title": "supports de cours de J-L Falcone",
                    "link": "https://gitlab.unige.ch/courses/jspub19/"
                }]
        }
    ];
    return (
        <React.Fragment>
            <h3>Enseignement de cours de bachelor (Hepia et HEIG-VD)</h3>
            {lectures.map(args =>
                <Lecture {...args} />)
            }
            <h3>Cours de formation continue pour entreprises externes</h3>
            {continuousTeaching.map(args =>
                <Lecture {...args} />)
            }
            <h3>Autres participations et interventions</h3>
            {secondaryLectures.map(args =>
                <Lecture {...args} />)
            }
        </React.Fragment>
    );
}

function Lecture({ title, description, school, years, topics, links }) {

    const classes = useStyles();

    return (
          <ExpansionPanel className={classes.root}>
            <ExpansionPanelSummary classes={{root:classes.summary, content:classes.content}}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography className={classes.heading}><span><strong>{title}</strong></span><span><SchoolYears years={years} school={school} /></span></Typography>
              <Typography className={classes.secondaryHeading}>{description}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.paneldetails}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {topics && <React.Fragment>
                        <h4 className={classes.h4}>thèmes abordés</h4>
                        <ul className={classes.ul}>
                            {topics.map(t => <li>{t}</li>)}
                        </ul>
                    </React.Fragment>
                    }
                </Typography>
            </ExpansionPanelDetails>
            <ExpansionPanelDetails>
                <br />
                {
                    links.map(({ title, link }) => <Button color="primary" target="_blank" size="small" href={link}>{title}</Button>
                    )
                }
            </ExpansionPanelDetails>
          </ExpansionPanel>
      );
}

function LectureOld({ title, description, school, years, topics, links }) {

    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent className={classes.cardcontent}>
                <h1 className={classes.h1}>{title}</h1>
                <Typography variant="body2" color="textSecondary" component="p">
                    {description}
                    <div className={classes.chips}>
                        {topics && topics.map(t => <Chip className={classes.chip} label={t} />)}
                    </div>
                    {/*topics && <React.Fragment>
                        <h4 className={classes.h4}>thèmes abordés</h4>
                        <ul className={classes.ul}>
                            {topics.map(t => <li>{t}</li>)}
                        </ul>
                    </React.Fragment>
                    */}
                    <SchoolYears years={years} school={school} />
                </Typography>
            </CardContent>
            <CardActions>
                {
                    links.map(({ title, link }) => <Button color="primary" target="_blank" size="small" href={link}>{title}</Button>
                    )
                }
            </CardActions>
        </Card>
    );
}
function LectureOld2({ title, description, school, years, topics, links }) {

    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent className={classes.cardcontent}>
                <h1 className={classes.h1}>{title}</h1>
                <Typography variant="body2" color="textSecondary" component="p">
                    {description}
                    {topics && <React.Fragment>
                        <h4 className={classes.h4}>thèmes abordés</h4>
                        <ul className={classes.ul}>
                            {topics.map(t => <li>{t}</li>)}
                        </ul>
                    </React.Fragment>
                    }
                    <SchoolYears years={years} school={school} />
                </Typography>
            </CardContent>
            <CardActions>
                {
                    links.map(({ title, link }) => <Button color="primary" target="_blank" size="small" href={link}>{title}</Button>
                    )
                }
            </CardActions>
        </Card>
    );
}

const SchoolYears = ({ years, school }) => (
    <React.Fragment>
        <strong>@{school} </strong><em>{years.join(", ")}</em>
    </React.Fragment>
);
