import React from 'react';
//import logo from './logo.svg';
import './App.css';

import Lectures from './Lectures';
import FollowUp from './FollowUp';
import Projects from './Projects';

import Container from '@material-ui/core/Container';
import Seminars from './Seminars';
import Book from './Book';


function App() {
  return (
    <div className="App">
      <Container maxWidth="md">
        <Lectures />
        <Seminars />
        <Projects />
        <FollowUp />
        <Book />
        <br />
      </Container>
        <br />
    </div>
  );
}

export default App;
