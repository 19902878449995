import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import MenuBookIcon from '@material-ui/icons/MenuBook';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}));

export default function Book() {

    const classes = useStyles();

    const books = [
        {
            title: "Functional Programming in Scala",
            author: "Paul Chiusano and Runar Bjarnason",
            year: 2014
        },
        {
            title: "Clean Code",
            subtitle: "A Handbook of Agile Software Craftsmanship",
            author: "Robert C. Martin",
            year: 2008
        },
        {
            title: "Effective Java - third edition",
            author: "Joshua Bloch ",
            year: 2008
        },
        {
            title: "Functional and Reactive Domain Modeling ",
            author: "Debasish Ghosh, Jonas Boner",
            year: 2017
        },
        {
            title: "Akka in Action",
            subtitle: "Raymond Roestenburg et al.",
            year: 2017
        },
        {
            title: "The Clean Coder",
            subtitle: "A Code of Conduct for Professional Programmers ",
            author: "Robert C. Martin",
            year: 2011
        },
        {
            title: "Domain-Driven Design Distilled",
            author: "Vaughn Vernon",
            year: 2016
        },
        {
            title: "Domain-Driven Design",
            subtitle: "Tackling Complexity in the Heart of Software",
            author: "Eric Evans",
            year: 2003
        },
        {
            title: "Implementing Domain-Driven Design",
            author: "Vaughn Vernon",
            year: 2013
        },
        {
            title: "User Story Mapping",
            subtitle: "Discover the whole story, build the right produce",
            author: "Jeff Patton et al.",
            year: 2014
        },
        {
            title: "Base de données et modèles de calcul ",
            subtitle: "Jean-Luc Heinaut",
            year: 2005
        },
        {
            title: "Database Modeling and Design - 4th edition",
            author: "Toby Teorey et al.",
            year: 2006
        },
        {
            title: "Fundamentals of Database Systems",
            author: "Elmasri and Navathe",
            year: 2017
        },
        {
            title: "NoSQL For Mere Mortals",
            author: "Dan Sullivan",
            year: 2015
        },
        {
            title: "Bases de données - 4e édition",
            subtitle: "Concepts, utilisation et développement",
            author: "Jean-Luc Heinaut ",
            year: 2018
        },
    ];
    return (
        <React.Fragment>
            <h3>Mes lectures</h3>
            <Grid container spacing={2}>
                {books.map( book => 
                    <Grid item container xs={4}>
                        <Grid item xs={1}>
                                <MenuBookIcon color="disabled" fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1">
                                {book.title}
                            </Typography>
                            {book.subtitle && <Typography variant="body2" display="block">
                                {book.subtitle}
                            </Typography>}
                            <Typography variant="body2" display="block">
                                <em>{book.author} - {book.year}</em>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
}
