import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import GroupIcon from '@material-ui/icons/Group';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        //backgroundColor: theme.palette.background.paper,

    },
    inline: {
        display: 'inline',
    },
    rest: {
        marginTop: '0.4em',
        "& a": {
            textDecoration: "none",
            color: "#3f51b5"
        }
    },
    abstract: {
        fontWeight: "lighter"
    }
}));

export default function Seminars() {

    const classes = useStyles();

    const seminars = [
        {
            title: "Project Managment - Agile methodologies Méthodologies Agiles, Master classe Luxe et Digital à CREA",
            abstract: `Deux jours de formation théorique et pratique pour les classes Master 
            International Digital Marketing and Communication et Master International Luxury Marketing de CREA`,
            date: "5 et 6 février 2020",
            location: "CREA, Genève"

        },{
            title: "Programmation artisanale et moderne avec le bon vieil objet Java",
            abstract: `Joël Cavat nous propose une rétrospective des nouveautés du langage offertes depuis 
            Java7 qui lui ont permis de garder du plaisir en programmant avec ce langage. Familiarisé avec Scala, 
            il partagera avec nous comment il a exploité au mieux ces nouveautés pour rester dans sa zone de confort. 
            L'objectif est de développer durant cette session, une petite librairie from scratch en pure 
            POJO (contemporain tout de même), sans annotation EJB, Spring ou autre... Il le réalisera en live 
            coding pour pouvoir échanger autour des bonnes pratiques et de sa passion pour l'artisanat logiciel.`,
            date: "28 novembre 2019",
            location: "The Java User Group of Lausanne",
            link: {
                "title": "lien meetup",
                "link": "https://www.meetup.com/fr-FR/JUGL-the-Java-User-Group-of-Lausanne/events/266435912/"
            }
        }, {
            title: "Programmation déclarative : un paradigme sous-exploité",
            abstract: `La programmation déclarative est un style de programmation qui décrit l'objectif sans préciser comment 
            y parvenir. Il décrit l'intention du développeur. Ce style s'oppose au paradigme impératif qui nous oblige à décrire 
            les étapes pour parvenir à notre objectif. Le langage SQL est l'exemple le plus connu.

            De nos jours, de nombreux frameworks, toolkits, librairies ont adopté ce style qui apporte de nombreux atouts. 
            Ce paradigme devient une technique contemporaine, mais malheureusement méconnue, de programmer.
            
            Dans ce séminaire, nous allons voir ses avantages au travers d'exemples, principalement sur des collections. `,
            date: "5 avril 2019",
            location: "Tech-lunch à Hepia",
            link: {
                "title": "lien",
                "link": "https://itigogie.hesge.ch/programmation-declarative/"
            }
        }
    ];

    console.log( seminars );

    return (
        <React.Fragment>
            <h3>Séminaires</h3>
            <List className={classes.root}>
                {seminars.map(args =>
                    <Seminar {...args} />)}
            </List>
        </React.Fragment>
    );
}

function Seminar({ title, abstract, date, location, link }) {

    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar>
                    <GroupIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            {abstract && [<span>Description: &nbsp;</span>, <span className={classes.abstract}>{abstract}</span>, <br />] }
                        </Typography>
                        <div className={classes.rest}>
                            {date} -&nbsp;
                            {location} 
                            {link && [<span> - </span>, <a href={link.link} rel="noopener noreferrer" target="_blank">{link.title}</a>]}
                        </div>
                    </React.Fragment>
                }
            />
        </ListItem>
    );
}