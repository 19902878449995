import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import DevicesIcon from '@material-ui/icons/Devices';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        //backgroundColor: theme.palette.background.paper,

    },
    inline: {
        display: 'inline',
    },
    rest: {
        marginTop: '0.4em',
        "& a": {
            textDecoration: "none",
            color: "#3f51b5"
        }
    }
}));

export default function FollowUps() {

    const classes = useStyles();

    const projects = [
        {
            title: "lp-modeler",
            abstract: "Lp modeler written in Rust",
            links: [{
                title: "https://github.com/jcavat/rust-lp-modeler",
                link: "https://github.com/jcavat/rust-lp-modeler"
            }, {
                title: "https://crates.io/crates/lp-modeler",
                link: "https://crates.io/crates/lp-modeler"
            }]
        },
        {
            title: "scalinea",
            abstract: "Linear and Programming Modeler DSL for Scala (WIP)",
            links: [{
                title: "https://github.com/jcavat/scalinea",
                link: "https://github.com/jcavat/scalinea"
            }]
        },{
            title: "relational-algebra-parser",
            abstract: "A pedagogic parser that aims to translate relational algebra into SQL. Written in Scala with Fastparse 2.x (WIP)",
            links: [{
                title: "https://github.com/jcavat/relational-algebra-parser",
                link: "https://github.com/jcavat/relational-algebra-parser"
            }]
        },
        {
            title: "Dojo Hepia - looking for funding",
            abstract: `Pedagogic platform for learning programming. the project was developed by a 
            student as part of a master's thesis that I followed. Two to three months of development
             are required to be fully functional.`,
            links: []
        }

    ];

    return (
        <React.Fragment>
            <h3>Projets personnels en cours</h3>
            <List className={classes.root}>
                {projects.map(args =>
                    <FollowUp {...args} />)}
            </List>
        </React.Fragment>
    );
}

function FollowUp({ title, abstract, links }) {

    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar>
                    <DevicesIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                        </Typography>
                        {abstract}
                        {abstract && <br />}
                        {
                            links.map( link => 
                                <div className={classes.rest}>
                                    <a href={link.link} rel="noopener noreferrer" target="_blank">{link.title}</a>
                                </div>
                                )
                        }
                    </React.Fragment>
                }
            />
        </ListItem>
    );
}