import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import SchoolIcon from '@material-ui/icons/School';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        //backgroundColor: theme.palette.background.paper,

    },
    inline: {
        display: 'inline',
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    rest: {
        marginTop: '0.4em',
        "& a": {
            textDecoration: "none",
            color: "#3f51b5"
        }
    },
    description: {
        fontWeight: "lighter"
    }
}));

export default function FollowUps() {

    const seminars = [
        {
            title: "Apache Kafka - analyse et application dans le traitement des données de compteurs intelligents",
            student: "Jossef Roncal Villanueva",
            year: "2021",
            technologies: ["Scala", "Kafka"],
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1903/ITI_IN_memoire_diplome_RoncalVillanueva_Albuquerque_Cavat_2021.pdf"
            }]
        },
        {
            title: "Générateur statique d'une plateforme d'apprentissage dynamique",
            student: "Jorge José Goncalves",
            year: "2021",
            technologies: ["pandoc", "bash"],
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1884/ITI_IN_memoire_diplome_JoseGoncalves_Malaspinas_Cavat_2021.pdf"
            }]
        },
        {
            title: "Réalisation d'un simulateur permettant l'évaluation d'algorithmes d'intelligence artificielle appliqués à l'analyse boursière",
            student: "Nicolas Paschoud",
            year: "2020",
            technologies: ["Scala", "Akka", "Kafka"],
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1738/ITI_IN_memoire_diplome_Paschoud_Cavat_2020.pdf"
            }]
        },
        {
            title: "- confidentiel -",
            student: "Lucas Pallud",
            partner: "Rolex",
            year: "2020",
            links: []
        },
        {
            title: "Planification d'horaires de cours avec gestion des contraintes et prise en compte des préférences ",
            student: "Sébastien Mata",
            technologies: ["Scala", "Scalinea", "CBC/Gurobi solvers"],
            year: "2020",
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1721/ITI_IN_memoire_diplome_Mata_Cavat_2020.pdf"
            }]
        },
        {
            title: "Outil de streaming vidéo pédagogique ",
            student: "Cédric Dürrenmatt",
            technologies: ["Rust"],
            year: "2020",
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1730/ITI_IN_memoire_diplome_Durrenmatt_Cavat_2020.pdf"
            }]
        },
        {
            title: "Conception d'un framework pour la gestion des collaborations du Cern ",
            student: "Maxime Clercq",
            year: "2020",
            partner: "CERN",
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1742/ITI_IN_memoire_diplome_Clercq_Cavat_2020.pdf"
            }]
        },
        {
            title: "Outil en ligne de commande pour la gestion et l'affectation de projets étudiants",
            student: "Naores Alwan",
            year: "2020",
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1799/ITI_IN_memoire_diplome_Alwan_Albuquerque_2020.pdf"
            }]
        },
        {
            title: "- confidentiel - (travail de Master)",
            student: "Fabio Vitali",
            partner: "2CS",
            technologies: ["Scala", "FastParse 2"],
            year: "2020",
            links: []
        },
        {
            title: "Dojo Hepia - Une plateforme d’apprentissage de programmationen ligne",
            student: "Alexandre Vanini",
            abstract: `Outil pédagogique pour l’apprentissage de langages de programmation. 
            Il se base sur des idées existantes, non libres, comme « Codewars » et sur le principe des 
            arts martiaux oùles « Katas » permettent de répéter des mouvements connus pour 
            perfectionner sa technique et de l’utiliser de manière instinctive lors d’une situation réelle`,
            year: "2019",
            technologies: ["Java11", "Javalin"],
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1637/ITI_IN_memoire_diplome_Vanini_Cavat_Malaspinas_2019.pdf"
            }]
        },
        {
            title: "Développement complet d’un système d’annonces basé sur le modèle du vide-grenier",
            student: "Jérémy Favre",
            abstract: `Achats et ventes d'articles avec classification automatiques des objets `,
            technologies: ["Scala", "Akka-http", "Ionic", "Angular"],
            year: "2019",
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1615/ITI_IN_memoire_diplome_Favre_Cavat_Albuquerque_2019.pdf"
            }]
        },
        {
            title: "Gestionnaire générique de ressources",
            student: "Osvaldo Pinto De Oliveira",
            technologies: ["Java11", "Javalin"],
            year: "2019",
            links: [{
                "title": "thèse de bachelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1628/ITI_IN_memoire_diplome_PintoOliveira_Cavat_Albuquerque_2019.pdf"
            }]
        },
        {
            title: "Application Web pour l’interaction pédagogique",
            student: "Melvin Gay",
            technologies: ["Scala", "Akka-http"],
            year: "2019",
            links: [{
                "title": "thèse de bathelor",
                "link": "https://gradechelor_pub.hesge.ch/studentFiles/1617/ITI_IN_memoire_diplome_Gay__Cavat_Albuquerque_2019.pdf"
            }]
        },
    ];

    return (
        <React.Fragment>
            <h3>Suivi de travaux de bachelor</h3>
            <Grid container spacing={2}>
                {seminars.map(args => (
                    <Grid item key={args.title} xs={6}>
                    <FollowUp {...args} />
                    </Grid>
                ))}

            </Grid>

        </React.Fragment>
    );
}

function FollowUp({ title, student, technologies, abstract, year, links }) {

    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar>
                    <SchoolIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                        {student}, {year}
                        <br />
                        </Typography>
                        {technologies && [<span>Technologies: </span>, <span className={classes.description}>{technologies.join(", ")}</span>, <br />]}
                        {abstract && [<span>Description: &nbsp;</span>, <span className={classes.description}>{abstract}</span>, <br />]}
                        {abstract && <br />}
                        {
                            links.map( link => 
                                <div className={classes.rest}>
                                    <a href={link.link} rel="noopener noreferrer" target="_blank">{link.title}</a>
                                </div>
                                )
                        }
                    </React.Fragment>
                }
            />
        </ListItem>
    );
}